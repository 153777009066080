import(/* webpackMode: "eager" */ "/var/www/mtpl_nextjs/app/CountryProvider.js");
import(/* webpackMode: "eager" */ "/var/www/mtpl_nextjs/app/DynamicFooter.js");
import(/* webpackMode: "eager" */ "/var/www/mtpl_nextjs/app/GTMComponents.js");
import(/* webpackMode: "eager" */ "/var/www/mtpl_nextjs/app/L_Header.js");
import(/* webpackMode: "eager" */ "/var/www/mtpl_nextjs/app/Loader.js");
import(/* webpackMode: "eager" */ "/var/www/mtpl_nextjs/app/LoaderProvider.js");
import(/* webpackMode: "eager" */ "/var/www/mtpl_nextjs/app/ModalProvider.js");
import(/* webpackMode: "eager" */ "/var/www/mtpl_nextjs/app/Pathname.js");
import(/* webpackMode: "eager" */ "/var/www/mtpl_nextjs/app/SideMenu.js");
import(/* webpackMode: "eager" */ "/var/www/mtpl_nextjs/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/var/www/mtpl_nextjs/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/var/www/mtpl_nextjs/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/var/www/mtpl_nextjs/styles/global.css")