"use client";
import React, { createContext, useContext, useState } from "react";

const ModalContext = createContext();

const ModalProvider = ({ children }) => {
  const [clients, setClients] = useState({});
  const handleCloseClick = () => {
    var testimonialvid = document.getElementById("testimonialvid");
    var iframe = testimonialvid.querySelector("iframe");
    iframe.src = "";
  };
  return (
    <ModalContext.Provider
      value={{
        clients,
        setClients,
        handleCloseClick,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useLoader must be used within a ModalProvider");
  }
  return context;
};
export default ModalProvider;
