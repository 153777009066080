"use client";

import { useEffect, useState } from "react";
import Script from "next/script";
import { usePathname } from "next/navigation";
import Cookies from "js-cookie";

const GTMComponent = () => {
  const [showGTM, setShowGTM] = useState(false);

  const pathname = usePathname();

  Cookies.set("moontech", "value", {
    domain: "moontechnolabs.com",
    expires: 7,
  });

  useEffect(() => {

    if (pathname !== "/portfolios") {
      const timer = setTimeout(() => {
        setShowGTM(true);
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [pathname]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];

      function gtag() {
        window.dataLayer.push(arguments);
      }

      gtag("js", new Date());
      gtag("config", "GTM-NKRQSXS", {
        linker: {
          domains: ["moontechnolabs.com"],
        },
      });

      gtag("get", "G-MY07G7E5PZ", "client_id", function (client_id) {
        document.cookie = "ClientIDmtpl=" + client_id;
      });

      gtag("get", "G-MY07G7E5PZ", "page_referrer", function (client_resource) {
        document.cookie = "Clientreferrer=" + client_resource;
      });

      function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        return decodeURIComponent(results[2].replace(/\+/g, " "));
      }

      function getCookie(name) {
        const match = document.cookie.match(
          new RegExp("(^| )" + name + "=([^;]+)")
        );
        if (match) return match[2];
      }

      function updateFields(prefix) {
        const clientId = getCookie("ClientIDmtpl");
        const referrer = getCookie("Clientreferrer");
        const utmSource = getParameterByName(
          "utm_source",
          window.location.href
        );

        function updateFieldById(id, value) {
          const element = document.getElementById(id);
          if (element) {
            element.value = value || "";
          }
        }

        updateFieldById(`${prefix}_ga_client_id`, clientId);
        updateFieldById(
          `${prefix}_ga_referrer_id`,
          referrer !== "undefined" && referrer !== null
            ? referrer
            : utmSource !== null
            ? utmSource
            : ""
        );
        updateFieldById(`${prefix}_gc_lid_id`, getCookie("_gclid"));
        updateFieldById(`${prefix}_gc_src`, getCookie("_gclsrc"));
        updateFieldById(`${prefix}_gt`, getCookie("_gt"));
        updateFieldById(`${prefix}_gcn`, getCookie("_gcn"));
        updateFieldById(`${prefix}_gid`, getCookie("_gid"));
      }

      const prefixes = [
        "inq",
        "cll",
        "hire",
        "cnt",
        "cnt_bottom",
        "ftr_frm",
        "banner_frm",
        "mid_frm",
      ];

      prefixes.forEach((prefix) => updateFields(prefix));
    }
  }, []);

  return (
    <>
      <Script
      defer
        id="youtube-script"
        strategy="lazyOnload"
        src="https://www.youtube.com/iframe_api"
      ></Script>
      <Script
      defer
        strategy="lazyOnload"
        id="gatag-script"
        src="https://www.google-analytics.com/analytics.js"
      ></Script>

      {showGTM && (
        <Script
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
        $(document).ready(function () {
        setTimeout(() => {(function (e, t, a, r, n)
          {e[r] = e[r] || [];
            e[r].push({"gtm.start": new Date().getTime(),event: "gtm.js"});
            var g = t.getElementsByTagName(a)[0],
            m = t.createElement(a);
            m.async = true;
            m.src = "https://www.googletagmanager.com/gtm.js?id=GTM-NKRQSXS";
            g.parentNode.insertBefore(m, g);})
            (window, document, "script", "dataLayer");
          }, 2000);
    });
  `,
          }}
        />
      )}
     
      <Script
        strategy="lazyOnload"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify([
            {
              "@context": "https://schema.org",
              "@type": "PostalAddress",
              streetAddress: "500 N Michigan Avenue, #600, Chicago IL 60611",
              addressLocality: "Chicago",
              addressRegion: "IL",
              postalCode: "60611",
              addressCountry: "US",
              telephone: "+1 (620) 330-9814",
            },
            {
              "@context": "https://schema.org",
              "@type": "PostalAddress",
              streetAddress: "150 W 25th St, New York, NY 10001",
              addressLocality: "New York",
              addressRegion: "NY",
              postalCode: "10001",
              addressCountry: "US",
              telephone: "+1 (620) 330-9814",
            },
            {
              "@context": "https://schema.org",
              "@type": "PostalAddress",
              streetAddress:
                "C-105, Ganesh Meridian,S.G.Hwy, Ahmedabad, GJ 380060",
              addressLocality: "Ahmedabad, Gujarat",
              addressRegion: "India",
              postalCode: "380060",
              addressCountry: "IND",
              telephone: "+91-79-40055109",
            },

            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              name: "Moon Technolabs",
              image:
                "https://cdn.moontechnolabs.com/live/images/moon_new_logo_tm.svg",
              "@id": "https://www.moontechnolabs.com/",
              url: "https://www.moontechnolabs.com/",
              telephone: "+1 (620) 330-9814 ",
              priceRange: "$25-$49/hr",
              address: {
                "@type": "PostalAddress",
                streetAddress: "500 N Michigan Avenue, #600, Chicago IL 60611",
                addressLocality: "Chicago",
                addressRegion: "IL",
                postalCode: "60611",
                addressCountry: "US",
              },
              geo: {
                "@type": "GeoCoordinates",
                latitude: 41.8911686,
                longitude: -87.6246995,
              },
              openingHoursSpecification: {
                "@type": "OpeningHoursSpecification",
                dayOfWeek: [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                ],
                opens: "10:00",
                closes: "20:00",
              },
              sameAs: [
                "https://www.facebook.com/moontechnolabs/",
                "https://twitter.com/moontechnolabs",
                "https://www.linkedin.com/company/moon-technolabs-pvt-ltd",
                "https://www.instagram.com/moontechnolabs/",
                "https://www.youtube.com/@moontechnolabs",
                "https://www.pinterest.com/moontechnolabs/",
              ],
            },
            {
              "@context": "http://schema.org",
              "@graph": [
                {
                  "@id": "https://www.moontechnolabs.com/",
                  "@type": "Organization",
                  name: "Moon Technolabs",
                  alternateName: "Moon Technolabs Pvt Ltd",
                  url: "https://www.moontechnolabs.com/",
                  logo: "https://cdn.moontechnolabs.com/live/images/moon_new_logo_tm.svg",
                  contactPoint: [
                    {
                      "@type": "ContactPoint",
                      telephone: "+1(620)330-9814",
                      contactType: "sales",
                      email: process.env.NEXT_PUBLIC_CONTACT_EMAIL,
                      availableLanguage: ["English"],
                    },
                  ],
                  sameAs: [
                    "https://www.facebook.com/moontechnolabs/",
                    "https://twitter.com/moontechnolabs",
                    "https://www.linkedin.com/company/moon-technolabs-pvt-ltd",
                    "https://www.instagram.com/moontechnolabs/",
                    "https://www.youtube.com/@moontechnolabs",
                    "https://www.pinterest.com/moontechnolabs/",
                  ],
                },
                {
                  "@type": "ProfessionalService",
                  parentOrganization: {
                    name: "Moon Technolabs Pvt Ltd",
                  },
                  name: "Moon Technolabs USA",
                  image:
                    "https://www.moontechnolabs.com/assets_website/images/new_logo_blue3.png",
                  address: {
                    "@type": "PostalAddress",
                    streetAddress: "500 N Michigan Avenue, #600",
                    addressLocality: "Chicago",
                    addressRegion: "Illinois",
                    postalCode: "60611",
                    telephone: "+1 620-330-9814",
                  },
                  openingHoursSpecification: [
                    {
                      "@type": "OpeningHoursSpecification",
                      dayOfWeek: [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                      ],
                      opens: "10:00",
                      closes: "20:00",
                    },
                  ],
                  priceRange: "$",
                },
                {
                  "@type": "ProfessionalService",
                  parentOrganization: {
                    name: "Moon Technolabs Pvt Ltd",
                  },
                  name: "Moon Technolabs India",
                  image:
                    "https://www.moontechnolabs.com/assets_website/images/new_logo_blue3.png",
                  address: {
                    "@type": "PostalAddress",
                    streetAddress: "C 105A Ganesh Meridian",
                    addressLocality: "Ahmedabad",
                    addressRegion: "Gujarat",
                    postalCode: "380060",
                    telephone: "+91-79-40055109",
                  },
                  openingHoursSpecification: [
                    {
                      "@type": "OpeningHoursSpecification",
                      dayOfWeek: [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                      ],
                      opens: "10:00",
                      closes: "20:00",
                    },
                  ],
                  priceRange: "$",
                },
              ],
            },
          ]),
        }}
      />

      <Script
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
          window['GoogleAnalyticsObject'] = 'ga';
          window['ga'] = window['ga'] || function() {
              (window['ga'].q = window['ga'].q || []).push(arguments)
          };
        `,
        }}
      />

      <Script
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
          var showHiddenElements = localStorage.getItem('showHiddenElements');
          if (showHiddenElements === 'true') {
              $('.testBox').addClass('active'); // Show hidden elements
              $(".testimonial_load_more").css('display', 'none');
          }
          
    `,
        }}
      />
    </>
  );
};

export default GTMComponent;
