"use client"
import React from "react";
import { usePathname } from "next/navigation";
import dynamic from "next/dynamic";

const Header = dynamic(() => import("./layouts/header"));
const LandingHeader = dynamic(() => import("./layouts/LandingHeader"));
const L_Header = () => {

  const pathname = usePathname();

  const voipPaths = [
    "/voip-software-development-expert",
    "/webrtc-development-company-expert",
    "/asterisk-development-expert",
    "/macos-app-development-expert",
    "/billing-software-development-expert",
    "/accounting-software-development-expert",
    "/android-app-development-expert",
    "/node-js-development-expert",
    "/ios-app-development-expert",
    "/saas-development-expert",
    "/flutter-app-development-expert",
    "/hire-macos-developers-expert",
    "/meet-us-at-gitex-berlin",
    "/custom-software-development-expert",
    "/react-native-app-development-expert",
    "/wearable-app-development-expert",
    "/windows-app-development-expert"
  ];

  const isVoipPath = voipPaths.includes(pathname);

  return <> {isVoipPath ? <LandingHeader /> : <Header />}</>;
};

export default L_Header;