"use client";
import { useLoader } from "./LoaderProvider";

const Loader = () => {
  const { isLoading } = useLoader();

  return (
    <>
      {isLoading ? (
        <div className={`lds-roller_parent `}>
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Loader;
