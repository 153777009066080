"use client";
import { useEffect, useState } from "react";
import { useCountry } from "./CountryProvider";
import Cookies from "js-cookie";
import axios from "axios";
import { useLoader } from "./LoaderProvider";
import { useRouter } from "next/navigation";
import Image from "next/image";

const SideMenu = () => {
  const [popupOpen, setPopupOpen] = useState(false);
  const { data } = useCountry();
  const [country_code, setCountry_code] = useState(
    data.country_calling_code || "+1"
  );
  const [phone_no, setPhone_no] = useState("");
  const [validPhone, setValidPhone] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const cookiesData = Cookies.get();
  const router = useRouter();
  const { showLoader, hideLoader } = useLoader();

  const openPopup = () => {
    if (successMessage) {
      setSuccessMessage(false);
    }
    setPopupOpen(true);
  };     
  
  function handlephonekeyPress(e) {
    var key = e.key;
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  }
  const closePopup = () => {
    setPopupOpen(false);
  };
  var browser = (function () {
    if (typeof window === "undefined") {
      return "Not in a browser environment";
    }
    var test = function (regexp) {
      return regexp.test(window.navigator.userAgent);
    };
    switch (true) {
      case test(/edg/i):
        return "Microsoft Edge";
      case test(/trident/i):
        return "Microsoft Internet Explorer";
      case test(/firefox|fxios/i):
        return "Mozilla Firefox";
      case test(/opr\//i):
        return "Opera";
      case test(/ucbrowser/i):
        return "UC Browser";
      case test(/samsungbrowser/i):
        return "Samsung Browser";
      case test(/chrome|chromium|crios/i):
        return "Google Chrome";
      case test(/safari/i):
        return "Apple Safari";
      default:
        return "Other";
    }
  })();

  const phoneValidation = (phone, country) => {
    let phoneMessage = "";
    if (phone == "") {
      phoneMessage = "Enter phone number";
    } else if (!/[0-9]/.test(phone)) {
      phoneMessage = "Enter Valid phone number";
    } else if (phone.length != 10) {
      phoneMessage = "Phone number must be 10 digits";
    } else if (!country) {
      phoneMessage = "The country code field is required.";
    }
    return phoneMessage;
  };
  const apiUrl = process.env.NEXT_PUBLIC_API_URL + "api/phonePopupAPICall";

  const onSubmit = async (e) => {
    e.preventDefault();
    showLoader();
    let object = {
      country_code: country_code.includes("+")
        ? country_code.split("+")[1]
        : country_code,
      phone_no,
      client_id: cookiesData.ClientIDmtpl || "",
      client_referrer:
        cookiesData.Clientreferrer === "undefined"
          ? location.href
            .replaceAll("?", "$")
            .replaceAll("&", "$")
            .split("$")
            .filter((utm) => utm.includes("utm_source"))
            .map((utm) => utm.split("="))
            .flat()[1] || ""
          : cookiesData.Clientreferrer,
      gcn: cookiesData._gcn,
      gid: cookiesData._gid,
      gc_lid: cookiesData._gclid,
      gc_src: cookiesData._gclsrc,
      client_ip: data.ip || "",
      client_city: data.city || "",
      client_state: data.region || "",
      client_country: data.country_name || "",
      client_country_code: data.country_code || "",
      client_browser: browser || "",
      page_url: window.location.href || "",
      page_ref_url: window.location.href || "",
      subject: "Inquiry from calls",
    };
    let valid = phoneValidation(phone_no, country_code.trim());
    if (valid == "") {
      try {
        const response = await axios.post(apiUrl, object);

        if (response?.data?.status == 200) {
          hideLoader();
          setSuccessMessage(true);
          setTimeout(() => {
            closePopup();
          }, 1000);
          router.push(process.env.NEXT_PUBLIC_API_URL + "/thank-you");
        } else {
          setValidPhone(response.data.message.desc);

          hideLoader();
        }
      } catch (error) {
        setSuccessMessage(false);
        hideLoader();
      }
    } else {
      setValidPhone(valid);
      setSuccessMessage(false);
      hideLoader();
    }
  };

  useEffect(() => {
    if (data.country_code !== "IN") {
      var linkHover = document.querySelectorAll(".link_hover li a");
      linkHover.forEach(function (link) {
        link.addEventListener("mouseenter", function () {
          var varLinkActive = this.getAttribute("data-id");
          document.querySelectorAll(".link_hover li").forEach(function (li) {
            li.classList.remove("active");
          });
          this.parentElement.classList.add("active");
          document
            .querySelectorAll(".link_hover_div .tab-pane")
            .forEach(function (pane) {
              pane.classList.remove("in", "active");
            });
          document.getElementById(varLinkActive).classList.add("in", "active");
        });
      });
      let country_code = JSON.parse(localStorage.getItem("countryCode"));

      if (country_code !== "IN") {
        window.addEventListener("scroll", function () {
          if (window.scrollY <= 600) {
            hideElements();
          } else {
            var position =
              document.querySelector(".main_footer_div")?.offsetTop -
              window.scrollY;
            if (position > 300) {
              showElements();
            } else {
              hideElements();
            }
          }
        });
      }



      

      function hideElements() {
        [".meet-wrap", ".btn-call-float", ".fab_menu"].forEach(function (
          selector
        ) {
          document.querySelectorAll(selector).forEach(function (element) { });
        });
      }

      function showElements() {
        [".meet-wrap", ".btn-call-float", ".fab_menu"].forEach(function (
          selector
        ) {
          document.querySelectorAll(selector).forEach(function (element) { });
        });
      }

    
    }
  }, [data.country_code]);
  return (
    <>
      {data.country_code && data.country_code !== "IN" ? (
        <>
          <div className="call_icon_fixed us_show_banner">
            <a
              href="#"
              aria-label="open-call-popup"
              onClick={openPopup}
              className="btn-call-float  open-call-popup"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="phone-alt"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="svg-inline--fa fa-phone-alt fa-w-16"
                width="0.8em"
                height="0.8em"
              >
                <path
                  fill="#ffffff"
                  d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"
                  className
                />
              </svg>
            </a>
          </div>
          <div
            className="call-float-div"
            style={{ right: popupOpen ? "0px" : "-310px" }}
          >
            <div className="inner-call-float">
              <a
                href="#"
                aria-label="close-call-popup"
                onClick={closePopup}
                className="btn-call-float close-call-popup"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="arrow-right"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  className="svg-inline--fa fa-arrow-right fa-w-14"
                  width="1em"
                  height="1em"
                >
                  <path
                    fill="#0f6ead"
                    d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
                    className
                  />
                </svg>
              </a>
              <div className="info-call">
                <div className="text-center">
                  <h3 className="call-heading"> Call Us Now </h3>
                </div>
                <div className="text-center number-div">
                  <span className="phone-no">
                    <Image
                      className="lazy"
                      src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/usa.svg`}
                      alt="usa"
                      title="USA"
                      width={43}
                      height={31}
                      loading="lazy"
                      unoptimized
                    />
                    <a
                      href="tel:+16203309814"
                      style={{ color: "#fff", textDecoration: "none" }}
                    >
                      +1 (620) 330-9814
                    </a>
                  </span>
                  <span
                    className="phone-no mobile_hide_banner"
                    style={{ display: "none" }}
                  >
                    <Image
                      className="lazy"
                      src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/india.svg`}
                      alt="Moon Technolabs India Location"
                      title="India"
                      width={38}
                      height={27}
                      loading="lazy"
                      unoptimized
                    />
                    <a
                      href="tel:+917940055109"
                      style={{ color: "#fff", textDecoration: "none" }}
                    >
                      +91-79-40055109
                    </a>
                  </span>
                </div>
                <span className="or-text-label"> OR </span>
                <div id="quickCall">
                  <div className="form-input-div formfieldID">
                    <form onSubmit={onSubmit}>
                      <input
                        type="hidden"
                        name="_token"
                        defaultValue="xoHBchqunEDHYi40oA6OelY1TDvfXpxqngqNisOC"
                      />
                      <span className="country_code">
                        <input
                          type="text"
                          name="country_code"
                          id="country_code"
                          size={40}
                          className="txt-call-code"
                          aria-required="true"
                          aria-invalid="false"
                          defaultValue="+1"
                          value={data.country_calling_code}
                          placeholder="CC*"
                        />
                      </span>
                      <span className="phone_no">
                        <input
                          type="text"
                          name="phone_no"
                          size={40}
                          className={`txt-call-phone`}
                          aria-required="true"
                          onKeyPress={(e) => handlephonekeyPress(e)}
                          style={
                            validPhone === false
                              ? { border: "1px solid red" }
                              : {}
                          }
                          value={phone_no}
                          onChange={(e) => {
                            setPhone_no(e.target.value);
                          }}
                          aria-invalid="false"
                          placeholder="Phone No.*"
                        />
                      </span>
                      {validPhone != "" ? (
                        <span id="call_us_error_msg" style={{ color: "red" }}>
                          <label
                            id="phone_no-error"
                            className="error"
                            for="phone_no"
                          >
                            {phoneValidation(phone_no, country_code.trim())}
                          </label>
                        </span>
                      ) : null}

                      <div>
                        <input
                          type="hidden"
                          name="page_url"
                          value="page_url"
                          id="cll_page_url"
                        />
                        <input
                          type="hidden"
                          name="client_id"
                          value="client_id"
                          id="cll_ga_client_id"
                        />
                        <input
                          type="hidden"
                          name="client_referrer"
                          value="client_referrer"
                          id="cll_ga_referrer_id"
                        />
                        <input
                          type="hidden"
                          name="gc_lid"
                          value="gc_lid"
                          id="cll_gc_lid_id"
                        />
                        <input
                          type="hidden"
                          name="gc_src"
                          value="gc_src"
                          id="cll_gc_src"
                        />
                        <input
                          type="hidden"
                          name="gcn"
                          value="gcn"
                          id="cll_gcn"
                        />
                        <input
                          type="hidden"
                          name="gid"
                          value="gid"
                          id="cll_gid"
                        />
                        <input
                          type="hidden"
                          name="client_ip"
                          value="client_ip"
                          id="cll_ga_client_ip"
                        />
                        <input
                          type="hidden"
                          name="client_city"
                          value="client_city"
                          id="cll_ga_client_city"
                        />
                        <input
                          type="hidden"
                          name="client_state"
                          value="client_state"
                          id="cll_ga_client_state"
                        />
                        <input
                          type="hidden"
                          name="client_country"
                          value="client_country"
                          id="cll_ga_client_country"
                        />
                        <input
                          type="hidden"
                          name="client_browser"
                          value="client_browser"
                          id="cll_client_browser"
                        />

                        <input
                          type="hidden"
                          name="subject"
                          value="Inquiry from calls"
                          id="cll_client_subject"
                        />
                      </div>

                      <input
                        type="submit"
                        defaultValue="Request a Call Back"
                        className="btn btn-gradient-yellow-purple btn-block m-t-15 "
                      />
                      <div
                        className="alert alert-success"
                        id="call_sucess"
                        style={{ display: successMessage ? "block" : "none" }}
                      >
                        Thank You!,We will call you back soon.
                      </div>
                    </form>
                  </div>
                </div>
                <span className="or-text-label"> OR </span>
                <div className="email-div">
                  <span className="phone-no">You can send us mail</span>
                  <a
                    href={`mailto:${process.env.NEXT_PUBLIC_CONTACT_EMAIL}`}
                    className="phone-no"
                  >
                    {process.env.NEXT_PUBLIC_CONTACT_EMAIL}
                  </a>
                </div>
              </div>
            </div>
          </div>

     
        </>
      ) : null}
    </>
  );
};

export default SideMenu;
