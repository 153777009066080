"use client";

import { usePathname } from "next/navigation";
const Pathname = () => {
  const pathname = usePathname();
  return (
    <link
      rel="canonical"
      href={`${process.env.NEXT_PUBLIC_API_URL}${pathname}`}
    ></link>
  );
};

export default Pathname;
