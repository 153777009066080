"use client";

import { usePathname } from "next/navigation";
import dynamic from "next/dynamic";

const MainFooter = dynamic(() => import("./Footer"));
const ContactFooter = dynamic(() => import("./(contact)/contact-us/components/Footer"));
const Landing_Footer = dynamic(() => import("./Landing_Footer"));

export default function DynamicFooter() {
    const pathname = usePathname();

    const landingPaths = [
    "/voip-software-development-expert",
    "/webrtc-development-company-expert",
    "/asterisk-development-expert",
    "/macos-app-development-expert",
    "/billing-software-development-expert",
    "/accounting-software-development-expert",
    "/android-app-development-expert",
    "/node-js-development-expert",
    "/ios-app-development-expert",
    "/saas-development-expert",
    "/flutter-app-development-expert",
    "/hire-macos-developers-expert",
    "/custom-software-development-expert",
    "/react-native-app-development-expert",
    "/wearable-app-development-expert",
    "/windows-app-development-expert",
    ];

    const noFooterPaths = [
        "/meet-us-at-gitex-berlin"
    ];

    const isLandingPage = landingPaths.includes(pathname);
    const isNoFooterPage = noFooterPaths.includes(pathname);

    if (isNoFooterPage) {
        return null;
    }

    if (pathname === "/contact-us") {
        return <ContactFooter />;
    } else if (isLandingPage) {
        return <Landing_Footer />;
    } else {
        return <MainFooter />;
    }
}
